import { FC, memo, useEffect, useState } from 'react'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { Swiper as SwiperInstance, Mousewheel } from 'swiper'
import cn from 'classnames'
import SliderTop from './SliderTop'
import styles from './CommonSlider.module.scss'
import { useRouter } from 'next/dist/client/router'
import MaxWidth from '../layout/MaxWidth'

interface ICommonSlider {
  title: string | JSX.Element
  items: JSX.Element[]
  name?: string
  href?: string
  total?: number
  disableTopHref?: boolean
  showTags?: boolean
  bigTitle?: boolean
  sliceTags?: boolean
  slidesPerView: 2 | 3 | 6
  className?: string
  wrapperClassName?: string
  withTopPadding?: boolean
  oneSlideOnMobile?: boolean
  work?: boolean
  hideArrows?: boolean
}

const CommonSlider: FC<ICommonSlider> = ({
  title,
  href,
  total,
  items,
  disableTopHref,
  slidesPerView,
  withTopPadding,
  oneSlideOnMobile,
  work,
  className,
  wrapperClassName,
  hideArrows,
}) => {
  const [swiper, setSwiper] = useState<SwiperInstance>()
  const { asPath } = useRouter()

  const options: SwiperProps = {
    threshold: 2,
    longSwipesRatio: 0.9,
    slidesPerView: 'auto',
    onSwiper: (s) => {
      setSwiper(s)
    },
    modules: [Mousewheel],
    mousewheel: {
      forceToAxis: true,
    },
  }

  useEffect(() => {
    if (swiper) {
      swiper.setProgress(0)
    }
  }, [asPath])

  return (
    <section
      className={cn(
        styles.wrapper,
        withTopPadding && styles.topPadding,
        wrapperClassName
      )}
    >
      <MaxWidth work={work}>
        <SliderTop
          title={title}
          href={href}
          total={total}
          onPrev={() => swiper.slidePrev()}
          onNext={() => swiper.slideNext()}
          disableHref={disableTopHref}
          hideButtons={hideArrows}
        />
        <Swiper {...options} className={cn(styles.slider, className)}>
          {items.map((post, ind) => (
            <SwiperSlide
              key={`${title}_${ind}`}
              className={cn(
                styles.slide,
                slidesPerView === 3 && styles.three,
                slidesPerView === 2 && styles.twoSlides,
                slidesPerView === 6 && styles.six,
                oneSlideOnMobile && styles.oneOnMobile
              )}
            >
              {post}
              <div className={styles.safariFix} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MaxWidth>
    </section>
  )
}

export default memo(CommonSlider)
